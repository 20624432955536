import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { Box } from '@twilio-paste/box';
import { Stack } from '@twilio-paste/stack';
import { Spinner } from '@twilio-paste/spinner';
import Changelog from '@twilio-paste/spinner/CHANGELOG.md';
import { Callout, CalloutTitle, CalloutText } from '../../../components/callout';
import { SiteLink } from '../../../components/SiteLink';
import { SidebarCategoryRoutes } from '../../../constants';
export const pageQuery = graphql`
  {
    allPasteComponent(filter: {name: {eq: "@twilio-paste/spinner"}}) {
      edges {
        node {
          name
          description
          status
          version
        }
      }
    }
    mdx(fields: {slug: {eq: "/components/spinner/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentHeader = makeShortcode("ComponentHeader");
const PageAside = makeShortcode("PageAside");
const LivePreview = makeShortcode("LivePreview");
const ChangelogRevealer = makeShortcode("ChangelogRevealer");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <ComponentHeader name={props.pageContext.frontmatter.title} categoryRoute={SidebarCategoryRoutes.COMPONENTS} githubUrl="https://github.com/twilio-labs/paste/tree/main/packages/paste-core/components/spinner" storybookUrl="/?path=/story/components-spinner--default" data={props.data.allPasteComponent.edges} mdxType="ComponentHeader" />
    <hr></hr>
    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <h2>{`Guidelines`}</h2>
        <h3>{`About Spinner`}</h3>
        <p>{`The spinner is an icon component that can be used in situations where you would like to communicate a loading state to the user. It inherits a lot of properties from the `}<a parentName="p" {...{
            "href": "/icons/usage-guidelines/"
          }}>{`Icon component`}</a>{`.`}</p>
        <Callout mdxType="Callout">
  <CalloutTitle mdxType="CalloutTitle">Accessible animation</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    The spinner animation will automatically respect user preferences set at the operating system level via the prefers
    reduced motion API.
  </CalloutText>
        </Callout>
        <h2>{`Examples`}</h2>
        <h3>{`Default`}</h3>
        <p>{`The default Spinner settings are using the default icon size and color, which is designed to match our default text size and color.`}</p>
        <Callout mdxType="Callout">
  <CalloutTitle mdxType="CalloutTitle">Accessibility</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    You are required to set whether the Spinner is decorative or not, please follow the{' '}
    <SiteLink to="/icons/usage-guidelines#accessibility" mdxType="SiteLink">icon accessibility guidelines</SiteLink>.
  </CalloutText>
        </Callout>
        <LivePreview scope={{
          Spinner
        }} mdxType="LivePreview">
  {`<Spinner decorative={false} title="Loading" />`}
        </LivePreview>
        <h2>{`Sizing`}</h2>
        <p>{`To resize the Spinner set a `}<inlineCode parentName="p">{`size`}</inlineCode>{` prop. It will accept any valid `}<a parentName="p" {...{
            "href": "/tokens/#sizings"
          }}>{`icon size token`}</a>{` as it's value. These are conveniently designed to be 1:1 to font sizing, e.g. `}<inlineCode parentName="p">{`font-size-40`}</inlineCode>{` can be paired with `}<inlineCode parentName="p">{`icon-size-40`}</inlineCode>{`.`}</p>
        <LivePreview scope={{
          Stack,
          Spinner
        }} mdxType="LivePreview">
  {`<Stack orientation="horizontal" spacing="space30">
  <Spinner size="sizeIcon10" decorative={false} title="Loading" />
  <Spinner size="sizeIcon20" decorative={false} title="Loading" />
  <Spinner size="sizeIcon30" decorative={false} title="Loading" />
  <Spinner size="sizeIcon40" decorative={false} title="Loading" />
  <Spinner size="sizeIcon50" decorative={false} title="Loading" />
  <Spinner size="sizeIcon60" decorative={false} title="Loading" />
  <Spinner size="sizeIcon70" decorative={false} title="Loading" />
  <Spinner size="sizeIcon80" decorative={false} title="Loading" />
  <Spinner size="sizeIcon90" decorative={false} title="Loading" />
  <Spinner size="sizeIcon100" decorative={false} title="Loading" />
  <Spinner size="sizeIcon110" decorative={false} title="Loading" />
</Stack>`}
        </LivePreview>
        <h2>{`Setting a color`}</h2>
        <p>{`The Spinner, like any other icon, can be re-colored to meet your needs. Set an `}<inlineCode parentName="p">{`color`}</inlineCode>{` in the `}<a parentName="p" {...{
            "href": "/icons/usage-guidelines#adapting-the-icons-color"
          }}>{`same way you would with an icon`}</a>{`.`}</p>
        <LivePreview scope={{
          Stack,
          Spinner
        }} mdxType="LivePreview">
  {`<Spinner color="colorTextSuccess" decorative={false} title="Loading" />`}
        </LivePreview>
        <hr></hr>
        <h2>{`Usage Guide`}</h2>
        <h3>{`API`}</h3>
        <h4>{`Installation`}</h4>
        <pre><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`yarn add @twilio-paste/spinner - or - yarn add @twilio-paste/core
`}</code></pre>
        <h4>{`Usage`}</h4>
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`import {Spinner} from '@twilio-paste/spinner';

const Component = () => {
  return <Spinner decorative={false} title="Loading" />;
};
`}</code></pre>
        <h4>{`Spinner Props`}</h4>
        <h5><inlineCode parentName="h5">{`as?: keyof JSX.IntrinsicElements`}</inlineCode></h5>
        <p>{`Changes the sematic element of the spinner wrapper.`}</p>
        <h5><inlineCode parentName="h5">{`decorative?: boolean`}</inlineCode></h5>
        <p>{`Whether or not the SVG is just visual flair or adds meaning to the page. Specifically for screenreaders to know whether to read out the title or not.`}</p>
        <h5><inlineCode parentName="h5">{`title?: string`}</inlineCode></h5>
        <p>{`The accessibility text that is read when screenreaders get to this component.`}</p>
        <h5><inlineCode parentName="h5">{`size?: IconSize`}</inlineCode></h5>
        <p>{`Used to change the size of the Spinner.`}</p>
        <h5><inlineCode parentName="h5">{`color?: TextColor`}</inlineCode></h5>
        <p>{`Sets the icon color to any of our text color tokens or currentColor, which inherits text color from the parent element.`}</p>
        <h5><inlineCode parentName="h5">{`delay?: number`}</inlineCode></h5>
        <p>{`Time delay in milliseconds of the animation.`}</p>
        <ChangelogRevealer mdxType="ChangelogRevealer">
  <Changelog mdxType="Changelog" />
        </ChangelogRevealer>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      